import React, { useState, useEffect, useRef, useSearch, createRef, performance } from 'react';
import ScrollToBottom from 'react-scroll-to-bottom';
import ReactDOMServer from 'react-dom/server';
import Cookies from 'js-cookie';
import ReactWordcloud from 'react-wordcloud';
import { createBrowserHistory } from 'history';
import Link from '@mui/material/Link';
import FocusTrap from '@mui/base/FocusTrap';
import { CCollapse } from '@coreui/react'
import performanceNow from 'performance-now';
import { BrowserRouter, Route, Switch as RouterSwitch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Menu, MenuItem, IconButton } from '@material-ui/core';
import { CopyBlock, CodeBlock, dracula } from "react-code-blocks";
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import VerifiedIcon from '@mui/icons-material/Verified';
import debounce from 'lodash.debounce';
import SvgIcon from '@mui/material/SvgIcon';
import Icon from '@material-ui/core/Icon';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Grow from '@material-ui/core/Grow';
import Fade from '@material-ui/core/Fade';
import Fab from '@mui/material/Fab';
import ClearIcon from '@mui/icons-material/Clear';
import LaunchIcon from '@mui/icons-material/Launch';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Slide from '@material-ui/core/Slide';
import Collapse from '@material-ui/core/Collapse';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import { Transition } from '@material-ui/core';
import Button from '@mui/material/Button';
import Box from '@material-ui/core/Box';
import Pagination from '@material-ui/lab/Pagination';
import TablePagination from '@mui/material/TablePagination';
import DOMPurify from 'dompurify';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DashboardIcon from '@mui/icons-material/Dashboard';
import WarningIcon from '@mui/icons-material/Warning';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import ErrorIcon from '@mui/icons-material/Error';
import HistoryIcon from '@mui/icons-material/History';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ArticleIcon from '@mui/icons-material/Article';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import InfoIcon from '@mui/icons-material/Info';
import Drawer from '@mui/material/Drawer';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ReactAudioPlayer from 'react-audio-player';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import { ThemeProvider } from '@material-ui/core/styles';
import { createTheme } from "@material-ui/core/styles";
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import MenuIcon from '@mui/icons-material/Menu';
import { Switch, useDarkreader } from 'react-darkreader';
import DownloadIcon from '@mui/icons-material/Download';


const drawerWidth = 240;

const theme = createTheme({
	palette: {
		primary: {
			main: '#2fbebc',
		},
	},
});
const headerTheme = createTheme({
	palette: {
		primary: {
			main: '#2fbebc',
		},
	},
});
const useStyles = makeStyles(theme => ({
  pageContainer: {
    width: 600,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },



        audio: {
                position: 'relative',
                justifyContent: 'center',
                alignItems: 'center',
                '& audio::-webkit-media-controls-timeline': {
                        display: 'none !important'
                },
        },
  
	searchResultsContainer: {
		position: 'relative',
		display: 'flex',
		marginTop: theme.spacing(2),
		textAlign: 'center',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		alignContent: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},

	loadContainer: {
		display: 'flex',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		textAlign: 'center',
		backgroundColor: 'rgba(0, 0, 0, 0.0)',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		alignContent: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	pageTitle: {
		backgroundColor: 'rgba(0, 0, 0, 0.0)',
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		alignItems: 'center',
		padding: '30px',
		[theme.breakpoints.down('sm')]: {
			visibility: 'none',
		},
	},
	responsiveTable: {
		width: '100%',
		backgroundColor: theme.palette.background.default,
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	hover: {
		[theme.breakpoints.down('sm')]: {},
		'&:hover': {
			backgroundColor: 'rgba(0, 0, 0, 0.2)',
		},
	},
	tableRow: {
		[theme.breakpoints.down('sm')]: {},
		'&:hover .nestedTableRow': {
			backgroundColor: 'rgba(0, 0, 0, 0.2)',
		},

	},
	nestedBox: {
		[theme.breakpoints.down('sm')]: {},
		'&:hover': {
			backgroundColor: 'rgba(0, 0, 0, 0.2)',
		},

	},
	
	codeBlockContainer: {
        height: 200,

	},


	
	nestedTableRow: {
		display: 'flex',
		backgroundColor: theme.palette.background.default,
		marginTop: '20',
		marginBottom: '20',
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
		'&:hover': {
			backgroundColor: 'rgba(0, 0, 0, 0.2)',
		},
	},
	nestedLineCell: {
		marginTop: '20',
		marginBottom: '20',
		width: '50%',
		height: '100%',
		paddingRight: '16px',
		borderBottom: 'none',
	},
	nestedTimingCell: {
		marginTop: '20',
		marginBottom: '20',
		width: '50%',
		paddingLeft: '16px',
		display: 'flex',
		borderBottom: 'none',
	},
  mobileTableRow: {
    display: 'flex',
    flexDirection: 'column'
  },
  mobileTableCell: {
    width: '100%'
  },
	nestedPlayCell: {
		width: '50%',
		marginTop: '20',
		marginBottom: '20',
		paddingLeft: '16px',
		display: 'flex',
		borderBottom: 'none',
	},
	buttonContainer: {
		display: 'flex',
		justifyContent: 'center',
		backgroundColor: 'rgba(0,0,0, 0.0)',
		alignItems: 'center',
		width: 'flex',
		[theme.breakpoints.down('sm')]: {
			width: 'flex',
		},
	},
	button: {
		marginBottom: '1%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {},
	},
	menuClass: {
		height: '100vh',
		width: '100vw',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
	},
	backgroundClass: {
		height: '100vh',
		width: '100vw',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'flex',
	},
	drawer: {
		variant: 'permanent',
			width: drawerWidth,
		flexShrink: 0,
		'& .MuiDrawer-paper': {
			width: drawerWidth,
			boxSizing: 'border-box'
		},
		[theme.breakpoints.down('sm')]: {},
	},
	barContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		width: '100%',
		margin: 'auto 0',
		[theme.breakpoints.down('sm')]: {},
	},
}));



//This is the main application
const Search = () => {
  const [totalPages, setTotalPages] = useState(0);

  // Total list pages is 37 because there's 361 episodes and they're paginated at 10 per page
  const totalListPages = 37;


  // Declaring arrays and strings
  const [files, setFiles] = useState([]);
  const [displayNames, setDisplayNames] = useState([]);
  const [officialLinks, setOfficialLinks] = useState([]);
  const [spotifyLinks, setSpotifyLinks] = useState([]);
  const [descriptions, setDescriptions] = useState([]);

  const [transcript, setTranscript] = useState("");
  const [strSize, setStrSize] = useState("");
  const [cpuTime, setCpuTime] = useState("");
const [queriedWord, setQueriedWord] = useState("");

const filteredNames = displayNames.map(name => {
  const matches = name.match(/\d+/g); // find all integers in the string
  if (matches) {
    for (let i = 0; i < matches.length; i++) {
      const num = parseInt(matches[i], 10).toString(); // remove leading zeros from the integer
      name = name.replace(matches[i], num); // replace the original integer with the modified one
    }
  }
  return name;
});


// This gets the theme preference from a cookie
const themePreference = Cookies.get('theme');


// This calls the darkreader API
const [isDark, {toggle}] = useDarkreader(themePreference === 'false');

// This sets a new theme preference in the cookie
const setThemePreference = (value) => {
Cookies.set('theme', value ? 'true' : 'false', { expires: 365, SameSite: 'None', secure: true });
};


// This handles switching the theme I guess idk
const handleThemeToggle = () => {
const newIsDark = !isDark;
toggle(newIsDark);
setThemePreference(newIsDark);
};


// This makes sure the theme preference is honored
useEffect(() => {
toggle(themePreference === 'true');
}, []);


// More arrays, strings, booleans etc
        const [loading, setLoading] = useState(false);
        const [query, setQuery] = useState('');
        const [page, setPage] = useState('')
        const [results, setResults] = useState([]);
        const [searchHistory, setSearchHistory] = useState([]);
        const [sortOrder, setSortOrder] = useState('desc');
        const [listSortOrder, setListSortOrder] = useState('asc');
        const [sortKey, setSortKey] = useState('episode');
        const [sortListKey, setSortListKey] = useState('episode');
        const [currentPage, setCurrentPage] = useState(null);
        const [currentListPage, setCurrentListPage] = useState('');
        const [perPage, setPerPage] = useState(10);
        const history = useHistory();
        const classes = useStyles({ loading });
        const [isOpen, setIsOpen] = useState(false);
        const [matches, setMatches] = useState(false);
        const [historyDrawerOpen, setHistoryDrawerOpen] = useState(false);
        const [perListPage, setPerListPage] = useState(10);


const memeHistory = createBrowserHistory();

// Creates selectedFile state
const [selectedFile, setSelectedFile] = useState(null);

const [lineNumber, setLineNumber] = useState(null);

const lineRef = useRef(null); // create a ref for the line to scroll to


// This creates the siblingCount integer which affects how many pagination buttons are visible
  const [siblingCount, setSiblingCount] = useState(0);
  
  // This expands the sibling count on desktop or contracts it on mobile
  useEffect(() => {
    setSiblingCount(matches ? 1 : 0);
  }, [matches]);




//This is a word cloud component I was testing but it sucks
const WordCloudComponent = () => {
  const [wordFrequency, setWordFrequency] = useState([]);




  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/api/txt');
        if (response.ok) {
          const data = await response.json();
          setWordFrequency(data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  // Convert the word frequency data to a word cloud array
  const wordCloudData = Object.entries(wordFrequency).map(([word, frequency]) => ({
    text: word,
    value: frequency,
  }));

  // Render the word cloud using the react-wordcloud library
  return (
 <div style={{justifyContent: 'center' }}>
  <ReactWordcloud options={{ rotations: 0, rotationAngles: 0 }} words={wordCloudData} />
 </div>
  )
};

// This creates the displayPage string
          const [displayPage, setDisplayPage] = useState("");




// This looks for resize events and updates how the drawer is displayed when switching from mobile to desktop or vice versa
        useEffect(() => {
          const handleWindowResize = () => {
                setMatches(window.innerWidth >= 600);
                if (isOpen === true && window.innerWidth >= 600 || window.innerWidth < 600 ) {
                  setIsOpen(false);
                }
                if (historyDrawerOpen === true && window.innerWidth >= 600 || window.innerWidth < 600 ) {
                  setHistoryDrawerOpen(false);
                }
          };
          handleWindowResize();
          window.addEventListener('resize', handleWindowResize);
          return () => window.removeEventListener('resize', handleWindowResize);
        }, []);


// This toggles the history drawer open or closed
        const handleHistoryToggle = (drawerType) => {
          if (drawerType === "history") {
            setHistoryDrawerOpen(!historyDrawerOpen);
          } 
        };


// This toggles the main drawer open or closed
        const handleDrawerToggle = (drawerType) => {
          if (isOpen === true) {
          	setIsOpen(false);
          }
          if (isOpen === false) {
          	setIsOpen(true);
          }

          if (historyDrawerOpen === true) {
            setHistoryDrawerOpen(!historyDrawerOpen);
          }
        };


// This updates the current search results page
  const handlePageUpdate = (event, value) => {
    setCurrentPage(value);
    handlePagesSubmit(event, value);
  };


//This paginates the search results with the API
        const handlePagesSubmit = async (event, value) => {
                event.preventDefault();
                setLoading(true);
                const sanitizedQuery = DOMPurify.sanitize(query);
                setResults([]);
                const response = await fetch(`/api/search?query=${sanitizedQuery}&page=${value}&per_page=${perPage}`);
                if (response.ok) {
                        const data = await response.json();
                        setResults(data.results);
                        setTotalPages(Math.ceil(data.total_results / perPage));
                };
                if (!response.ok) {
                    const data = await response.json();
                        if (data.error === 'noresults') {
                        setQueriedWord(data.queried_word);
                        setLoading(false);
                        setDisplayPage('/noresults');
                        };
                        if (data.error === 'noquery') {
                        setLoading(false);
                        setDisplayPage('/noquery');
                        };
                        if (data.error === 'badquery') {
                        setLoading(false);
                        setDisplayPage('/querytoocommon');
                        };

                };
        };

// This closes the drawer and updates the searchbar query when the user does a button press in the search history array
const handleHistorySubmit = (historyItem) => {
  setQuery(historyItem);
  setHistoryDrawerOpen(!historyDrawerOpen);
  handleDrawerToggle();
  handleHistory({
    preventDefault: () => {},
    target: {
      value: historyItem
    }
  });
};

// This handles a search history query
const handleHistory = async (event) => {
  event.preventDefault();
  setLoading(true);
  setCurrentPage(1);
  const sanitizedQuery = DOMPurify.sanitize(event.target.value);
  setResults([]);
  const response = await fetch(`/api/search?query=${sanitizedQuery}&per_page=${perPage}`);
                if (response.ok) {
                        const data = await response.json();
                        setResults(data.results);
                        setTotalPages(Math.ceil(data.total_results / perPage));
                };

                
                if (!response.ok) {
                                      const data = await response.json();
                        if (data.error === 'noresults') {
                        setQueriedWord(data.queried_word);
                        setLoading(false);
                        setDisplayPage('/noresults');
                        };
                        if (data.error === 'noquery') {
                        setLoading(false);
                        setDisplayPage('/noquery');
                        };
                        if (data.error === 'badquery') {
                        setLoading(false);
                        setDisplayPage('/querytoocommon');
                        };
                };
};


// This handles a standard search query with the API
  const handleSubmit = async (event) => {
      if (isOpen === true && window.innerWidth <= 600) {
          setIsOpen(!isOpen);
      };
      if (historyDrawerOpen === true) {
          setHistoryDrawerOpen(!historyDrawerOpen);
      };
      event.preventDefault();
      setLoading(true);
      setCurrentPage(1);
      if (query.length > 1000) {
          setLoading(false);
          setDisplayPage('/querytoolong');
          return;
      }
      const sanitizedQuery = DOMPurify.sanitize(query);
      setResults([]);
      const response = await fetch(`/api/search?query=${sanitizedQuery}&page=1&per_page=${perPage}`);
      if (response.ok) {
          const data = await response.json();
          setResults(data.results);
          setTotalPages(Math.ceil(data.total_results / perPage));
      };
      if (!response.ok) {
          const data = await response.json();
          if (data.error === 'noresults') {
              setQueriedWord(data.queried_word);
              setLoading(false);
              setDisplayPage('/noresults');
          };
          if (data.error === 'noquery') {
              setLoading(false);
              setDisplayPage('/noquery');
          };
                if (data.error === 'badquery') {
                        setLoading(false);
                        setDisplayPage('/querytoocommon');
                        };
      };
  };
  

// This handles the shuffle button event
  const handleShuffleButton = (event) => {
    setDisplayPage('/results');
    handleShuffle();
  };


// This handles the shuffle API call
        const handleShuffle = async () => {
                setLoading(true);
                setCurrentPage(1);
                setResults([]);
                
                const response = await fetch(`/api/search?query=random_key&page=1&per_page=${perPage}`);
                if (response.ok) {
                        const data = await response.json();
                        setResults(data.results);
                        setTotalPages(Math.ceil(data.total_results / perPage));
                };
                if (!response.ok) {
                    const data = await response.json();
                        if (data.error === 'noresults') {
                        setQueriedWord(data.queried_word);
                        setLoading(false);
                        setDisplayPage('/noresults');
                        };
                        if (data.error === 'noquery') {
                        setLoading(false);
                        setDisplayPage('/noquery');
                        };
                if (data.error === 'badquery') {
                        setLoading(false);
                        setDisplayPage('/querytoocommon');
                        };
                };
        };


// This checks for a search history cookie and creates one if one does not exist
// if a cookie does exist, it populates the searchhistory array with whatever is found in the cookie
useEffect(() => {
  const historyFromCookie = Cookies.get('history');
  if (historyFromCookie) {
    setSearchHistory(JSON.parse(historyFromCookie));
  }

  if (results.length > 0) {
    const queriedWord = results[0].queried_word;
    setQuery(queriedWord);
    if (!searchHistory.includes(queriedWord)) {
      setSearchHistory([...searchHistory, queriedWord]);
      Cookies.set('history', JSON.stringify([...searchHistory, queriedWord]), { expires: 365, SameSite: 'None', secure: true });
    }
    setDisplayPage('/results');
    setTimeout(() => {
      setLoading(false);
    }, null);
  }
}, [results]);


// This clears the search history
const handleClearHistory = (event) => {
  Cookies.remove('history', { SameSite: 'None', secure: true });
  setSearchHistory([]);
};



// This creates the files array
const [sortedFiles, setSortedFiles] = useState([...files]);


// This attempts to sort the transcript list and does not work
	const handleListSort = (key) => {
		setSortListKey(key);
		setListSortOrder(listSortOrder === 'asc' ? 'desc' : 'asc');
	};

// This updates the loading state to reflect a change in the transcript or files arrays
  useEffect(() => {
    if (displayPage === "/transcripts" && (transcript.length > 0 || files.length > 0)) {
  
      setDisplayPage('/transcripts');
      setTimeout(() => {
         setLoading(false);
      }, null);
    }
  }, [transcript, files, displayPage]);


// This empties the arrays and refs and shit whenever displayPage changes
  useEffect(() => {
    if (displayPage !== "/transcripts") {
      setTranscript("");
      setFiles([]);
      setOfficialLinks([]);
      setSpotifyLinks([]);
      setDescriptions([]);
      setLineNumber(null);
      lineRef.current = null;
    }
    if (displayPage !== "/results") {
    	setResults([]);
    }
  }, [displayPage]);



//This updates the current list page
  const handleListPageUpdate = (event, value) => {
    setCurrentListPage(value);
    handleListPagesSubmit(event, value);
  };

//This paginates the transcript list with the API
const handleListPagesSubmit = async (event, value) => {
  event.preventDefault();
  setLoading(true);
  setFiles([]);
  setTranscript('');
  setLineNumber(null);
  lineRef.current = null;
  const response = await fetch(`/api/vtt/list?per_page=${perListPage}&page=${value}`);
  if (response.ok) {
    const data = await response.json();
      setFiles(data.file_list);
      setDisplayNames(data.display_names);
      setOfficialLinks(data.official_links);
      setSpotifyLinks(data.spotify_links);
      setDescriptions(data.descriptions);
      setCpuTime(data.cpu_time);
      setStrSize(data.str_size);
    };
                    
                if (!response.ok) {
                    const data = await response.json();
                        setLoading(false);
                        setDisplayPage('/noresults');	
                        setQueriedWord(data.queried_word);
                };
};


//This pulls the transcript list from the API
const handlePullList = async (page = 1) => {
  setLoading(true);
  setFiles([]);
  setTranscript('');
  setLineNumber(null);
  lineRef.current = null;
  const response = await fetch(`/api/vtt/list?perPage=${perListPage}&page=${page}`);
  if (response.ok) {
    const data = await response.json();
    setFiles(data.file_list);
    setDisplayNames(data.display_names);
    setOfficialLinks(data.official_links);
    setSpotifyLinks(data.spotify_links);
    setDescriptions(data.descriptions);
    setCpuTime(data.cpu_time);
    setStrSize(data.str_size);
  };
                  
                if (!response.ok) {
                    const data = await response.json();
                        setQueriedWord(data.queried_word);
                        setLoading(false);
                        setDisplayPage('/noresults');	

                };
};


//This is the search history array
const SearchHistory = ({ searchHistory, handleHistorySubmit }) => {
  const reversedHistory = searchHistory.slice().reverse();
  return (
    <List>
      {reversedHistory.map((historyItem, index) => (
        <ListItem key={index} button onClick={() => handleHistorySubmit(historyItem)}>
          <ListItemText primary={historyItem} />
        </ListItem>
      ))}
    </List>
  );
};


//This scrolls the user to the top of the page whenever displayPage or currentPage changes
useEffect(() => {
  window.scrollTo(0, 0)
}, [currentPage, displayPage])






//This pulls a transcript from the API from search results view
const handlePullAndGo = async (file, lineNumber) => {
  setDisplayPage('/transcripts');
  lineRef.current = null;
  setLoading(true);
  setFiles([]);
  setTranscript('');
  setCpuTime(0);
  setStrSize(0);
  setSelectedFile(file);
  setLineNumber(lineNumber + 1); // set the line number in the state
  const response = await fetch(`/api/vtt/stream/${file}`);
  if (response.ok) {
    const data = await response.json();
    setTranscript(data.content);
    setCpuTime(data.cpu_time);
    setStrSize(data.str_size);
  } else {
    const data = await response.json();
    setQueriedWord(data.queried_word);
    setLoading(false);
    setDisplayPage('/noresults');
  }
};






//This pulls a single transcript from the API
const handlePullTranscript = async (file) => {
  setLoading(true);
  setFiles([]);
  setTranscript('');
  setLineNumber(null);
  lineRef.current = null;
  const response = await fetch(`/api/vtt/stream/${file}`);
  if (response.ok) {
    const data = await response.json();
    setTranscript(data.content);
    setCpuTime(data.cpu_time);
    setStrSize(data.str_size);
    setSelectedFile(file);
                };
                if (!response.ok) {
                    const data = await response.json();
                        setQueriedWord(data.queried_word);
                        setLoading(false);
                        setDisplayPage('/noresults');	

                };
};


//This decides whether to show the floating footer
const [showFairUseFooter, setShowFairUseFooter] = useState(false);

  useEffect(() => {
    const allowedPages = ["/about", "/dan", "/noquery", "/noresults", "/querytoolong", "/querytoocommon"];
    const disallowedPages = ["/results", "/transcripts", "/fairuse"];
    setShowFairUseFooter(allowedPages.includes(displayPage) && !disallowedPages.includes(displayPage));
  }, [displayPage]);




const [spotifyButtonDark, setSpotifyButtonDark] = useState(false);

useEffect(() => {
  if (themePreference === 'true') {
    setSpotifyButtonDark(true);
  } 
  if (themePreference === 'false') {
    setSpotifyButtonDark(false);
  }
}, [themePreference]);


  const [listCollapsed, setListCollapsed] = useState({});
  const handleListCollapse = (index) => {
    setListCollapsed({ ...listCollapsed, [index]: !listCollapsed[index] });
  };


        useEffect(() => {
           setListCollapsed({});
        }, [currentListPage, displayPage, selectedFile]);


  
	return (
<>





	<Box sx={{ display: 'flex' }}>

      

      <AppBar position="fixed" sx={{ bgcolor: '#2fbebc', zIndex: 9999 }}>
      <Toolbar>
        <div>
          <Typography style={{ display: matches ? 'block' : 'none' }} variant="h6" noWrap={true}>
            {window.location.hostname}
            
          </Typography>
        </div>
    <div style={{ display: 'flex', justifyContent: matches? 'flex-end' : 'center', width: '100%' }}>
       <div style={{ height: 50 }}>

          <SearchBar
            setListCollapsed={setListCollapsed}
            setResults={setResults}
            setFiles={setFiles}
            setDisplayPage={setDisplayPage}
            setQuery={setQuery}
            historyDrawerOpen={historyDrawerOpen}
            setHistoryDrawerOpen={setHistoryDrawerOpen}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            results={results}
            handleDrawerToggle={handleDrawerToggle}
            matches={matches}
            query={query}
            onSubmit={handleSubmit}
          />
           </div>
        </div>
      </Toolbar>
    </AppBar>



<SwipeableDrawer
  ModalProps={{disableAutoFocus: true, disableEnforceFocus: true}}
  open={isOpen}
  disableSwipeToOpen={false}
  onOpen={() => handleDrawerToggle()}
  onClose={() => handleDrawerToggle()}
  position="fixed"
  variant={matches ? "permanent" : "temporary"}
  sx={{
    zIndex: (theme) => theme.zIndex.searchResults + 1,
    width: drawerWidth,
    flexShrink: 0,
    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
  }}
>



  <Toolbar />
  <Box sx={{ overflow: 'auto' }}>
    <List>
      {['Shuffle', 'History', 'Transcripts'].map((text, index) => (
        <ListItem key={text} disablePadding>

<ListItemButton onClick={() => {
  if (index === 1) {
    handleHistoryToggle('history');
  } else if (index === 0) {
    setSelectedFile(null);
    setCurrentListPage(1);
    setFiles([]);
    setTranscript('');
    setDisplayPage('/results');
    handleShuffleButton();
    setListCollapsed({});
    if (isOpen === true && window.innerWidth <= 600) {
      setIsOpen(!isOpen);
    }
  } else if (index === 2) {
    setFiles([]);
    setTranscript('');
    setSelectedFile(null);
    setCurrentListPage(1);
    setDisplayPage('/transcripts');
    setListCollapsed({});
    handlePullList();
    if (isOpen === true && window.innerWidth <= 600) {
      setIsOpen(!isOpen);
    }




  }
}}>


            <ListItemIcon>
              {index === 0 ? <ShuffleIcon /> :
                index === 1 ? <HistoryIcon /> :
                  <ArticleIcon />
              }

            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
    <Divider />
    <List>
      {['About'].map((text, index) => (
        <ListItem key={text} disablePadding>
<ListItemButton onClick={() => {

if (index === 0) {
    setSelectedFile(null);
    setCurrentListPage(1);
    setFiles([]);
    setTranscript('');
    setDisplayPage('/about');
    if (isOpen === true && window.innerWidth <= 600) {
      setIsOpen(!isOpen);
    }

  }
}}>




          
            <ListItemIcon>
              {index % 2 === 0 ? <InfoIcon /> : <InfoIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  </Box>
  



  <Drawer
    ModalProps={{disableAutoFocus: true, disableEnforceFocus: true}}
    open={historyDrawerOpen}
    onClose={() => handleHistoryToggle("history")}
    sx={{
      zIndex: (theme) => theme.zIndex.searchResults + 1,
      width: drawerWidth,
      flexShrink: 0,
      [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
    }}
  >

  
    <Box sx={{ overflow: 'auto' }}>
     


      <List>
        <ListItem key="History" disablePadding>
          <ListItemText primary="History" />
        </ListItem>
      </List>
      <Divider />
      <List>
        <SearchHistory searchHistory={searchHistory} handleHistorySubmit={handleHistorySubmit} />
      </List>
  
      
    </Box>
  </Drawer>
  
  <div style={{ display: 'flex-end', position: 'fixed', bottom: 0, padding: 10 }} id="dark-mode-toggle">
    <Switch checked={isDark} onChange={handleThemeToggle} />

  </div>
</SwipeableDrawer>





      <Box component="main" sx={{ flexGrow: 1, p: 0 }}>



      
      <Toolbar />
      <Fade in={true}>

   <div className={classes.loadContainer}
        style={{
     paddingLeft: matches ? 240 : 0,
     }}>
        {loading ? (
         
          <Box sx={{ height: 40 }}>
<CssBaseline />
            <CircularProgress />
          </Box>
        ) : (
          null
        )}
      </div>
      </Fade>
      
<ThemeProvider theme={theme}>
     <div style={{ position: 'fixed', top: matches ? 65 : 56, left: 240, zIndex: 9999 }}>
       <Fade in={historyDrawerOpen}>
        <div style={{ padding: 10 }}>

<Fab id="clear-all-history-button" onClick={() => handleClearHistory()} style={{ backgroundColor: '#FFFFFF' }} variant="extended" size="small" aria-label="clear">
  <ClearIcon sx={{ mr: 1 }} />
  <Typography style={{ paddingRight: 8 }} variant="button">
  Clear
  </Typography>
</Fab> 

      </div>
    </Fade>
  </div>
</ThemeProvider>





<div id="fair-use-footer">
<ThemeProvider theme={theme}>
     <div style={{ position: 'fixed', top: matches ? 65 : 56, left: matches ? 240 : 0, zIndex: 1 }}>
       <Fade in={showFairUseFooter}>

<TableCell style={{ zIndex: '1', backgroundColor: 'white', borderBottom: 'none', width: matches ? 'calc(100% - 240px)' : '100%', position: 'fixed', bottom: 0, height: '50px', textAlign: 'center', padding: 0 }}>
<Divider /><br></br>

<Typography variant="caption">
  <Link
    style={{ position: 'relative', bottom: 7, cursor: 'pointer' }}
    target="_blank"
    rel="noreferrer"
    onClick={() => setDisplayPage('/fairuse')}
  >
    Fair Use Notice
  </Link>
</Typography>

</TableCell>

</Fade>
</div>
</ThemeProvider>
</div>






<div 
  className={classes.searchResultsContainer}
     style={{
     paddingLeft: matches ? 0 : 0,
     }}
>
  {displayPage === "/results" && (        

    <ThemeProvider theme={theme}>
        <SearchResults
          spotifyButtonDark={spotifyButtonDark}
          setSpotifyButtonDark={setSpotifyButtonDark}
          handlePullAndGo={handlePullAndGo}
          setListCollapsed={setListCollapsed}
          siblingCount={siblingCount}
          setDisplayPage={setDisplayPage}
          handlePageUpdate={handlePageUpdate}
          handlePagesSubmit={handlePagesSubmit}
          totalPages={totalPages}
          setTotalPages={setTotalPages}
          matches={matches}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          classes={classes}
          loading={loading}
          setLoading={setLoading}
          useEffect={useEffect}
          query={query}
          results={results}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          sortKey={sortKey}
          setSortKey={setSortKey}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          perPage={perPage}
          setPerPage={setPerPage}
        />
    </ThemeProvider>
  )}    
      </div>   








<div 
  className={classes.noQueryContainer}
     style={{
     paddingLeft: matches ? 0 : 0,
     }}
>
  {displayPage === "/noquery" && (



      <Fade in={true}>

    <ThemeProvider theme={theme}>
     
      <TableCell style={{ zIndex: '0', paddingBottom: '50px', borderBottom: 'none', paddingLeft: 20, paddingRight: 20, alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
       <div className={classes.pageContainer} style={{ height: '100%', paddingBottom: '0', borderBottom: 'none', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', alignText: 'left' }}>
        <Typography style={{ paddingBottom: 10, fontWeight: 'bold', fontSize: '20px' }}>404</Typography>
        <Divider style={{ padding: 0 }} orientation="horizontal" fullWidth="true" />
        <Typography style={{ padding: 0 }}>
        <p style={{ fontWeight: 'italic' }}>
        Youp! No query.
        </p>
        <Typography style={{ fontColor: 'gray', display: 'flex', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }} variant="h6" component="h5">
        <p>
        </p>
        </Typography>
        <p>
        </p>
        </Typography>
      </div>
              <ErrorOutlineIcon style={{ position: 'fixed', justifyContent: 'center', alignText: 'center', display: 'flex', paddingTop: 300, fontSize: '90px' }} />

        </TableCell>



        
    </ThemeProvider>

    
</Fade>

)}
</div>



<div 
  className={classes.noQueryContainer}
     style={{
     paddingLeft: matches ? 0 : 0,
     }}
>
  {displayPage === "/querytoocommon" && (



      <Fade in={true}>

    <ThemeProvider theme={theme}>
     
      <TableCell style={{ zIndex: '0', paddingBottom: '50px', borderBottom: 'none', paddingLeft: 20, paddingRight: 20, alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
       <div className={classes.pageContainer} style={{ height: '100%', paddingBottom: '0', borderBottom: 'none', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', alignText: 'left' }}>
        <Typography style={{ paddingBottom: 10, fontWeight: 'bold', fontSize: '20px' }}>404</Typography>
        <Divider style={{ padding: 0 }} orientation="horizontal" fullWidth="true" />
        <Typography style={{ padding: 0 }}>
        <p style={{ fontWeight: 'italic' }}>
        Youp! Query too common.
        </p>
        <Typography style={{ fontColor: 'gray', display: 'flex', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }} variant="h6" component="h5">
        <p>
        </p>
        </Typography>
        <p>
        </p>
        </Typography>
      </div>
              <ErrorOutlineIcon style={{ position: 'fixed', justifyContent: 'center', alignText: 'center', display: 'flex', paddingTop: 300, fontSize: '90px' }} />

        </TableCell>



        
    </ThemeProvider>

    
</Fade>

)}
</div>





<div 
  className={classes.noQueryContainer}
     style={{
     paddingLeft: matches ? 0 : 0,
     }}
>
  {displayPage === "/querytoolong" && (



      <Fade in={true}>

    <ThemeProvider theme={theme}>
     
      <TableCell style={{ zIndex: '0', paddingBottom: '50px', borderBottom: 'none', paddingLeft: 20, paddingRight: 20, alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
       <div className={classes.pageContainer} style={{ height: '100%', paddingBottom: '0', borderBottom: 'none', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', alignText: 'left' }}>
        <Typography style={{ paddingBottom: 10, fontWeight: 'bold', fontSize: '20px' }}>404</Typography>
        <Divider style={{ padding: 0 }} orientation="horizontal" fullWidth="true" />
        <Typography style={{ padding: 0 }}>
        <p style={{ fontWeight: 'italic' }}>
        Youp! Query too long.
        </p>
        <Typography style={{ fontColor: 'gray', display: 'flex', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }} variant="h6" component="h5">
        <p>
        </p>
        </Typography>
        <p>
        </p>
        </Typography>
      </div>
              <ErrorOutlineIcon style={{ position: 'fixed', justifyContent: 'center', alignText: 'center', display: 'flex', paddingTop: 300, fontSize: '90px' }} />

        </TableCell>



        
    </ThemeProvider>

    
</Fade>

)}
</div>







<div 
  className={classes.noResultsContainer}
     style={{
     paddingLeft: matches ? 0 : 0,
     }}
>
  {displayPage === "/noresults" && (



      <Fade in={true}>

    <ThemeProvider theme={theme}>
     
      <TableCell style={{ zIndex: '0', paddingBottom: '50px', borderBottom: 'none', paddingLeft: 20, paddingRight: 20, alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
       <div className={classes.pageContainer} style={{ height: '100%', paddingBottom: '0', borderBottom: 'none', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', alignText: 'left' }}>
        <Typography style={{ paddingBottom: 10, fontWeight: 'bold', fontSize: '20px' }}>404</Typography>
        <Divider style={{ padding: 0 }} orientation="horizontal" fullWidth="true" />
        <Typography style={{ padding: 0 }}>
        <p style={{ fontWeight: 'italic' }}>
        Youp! No results found for... 
        </p>
        <Typography style={{ overflowWrap: 'break-word', fontColor: 'gray', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }} variant="h6" component="h5">
        <p>
        "
        {queriedWord.length > 26
                  ? queriedWord.substr(0, 26) + '...'
                  : queriedWord}
        "
        </p>
        </Typography>
        <p>
        </p>
        </Typography>
      </div>
              <ErrorOutlineIcon style={{ position: 'fixed', justifyContent: 'center', alignText: 'center', display: 'flex', paddingTop: 300, fontSize: '90px' }} />

        </TableCell>



        
    </ThemeProvider>

    
</Fade>

)}
</div>








  {displayPage === "/about" && (

<div 
  className={classes.aboutContainer}
     style={{
     paddingLeft: matches ? 0 : 0,
     }}
>
      <Fade in={true}>

    <ThemeProvider theme={theme}>



      <TableCell style={{ zIndex: '0', paddingBottom: '50px', borderBottom: 'none', paddingLeft: 20, paddingRight: 20, alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
       <div className={classes.pageContainer} style={{ height: '100%', paddingBottom: '0', borderBottom: 'none', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', alignText: 'left' }}>
        <Typography style={{ paddingBottom: 10, fontWeight: 'bold', fontSize: '20px' }}>About</Typography>
        <Divider style={{ padding: 0 }} orientation="horizontal" fullWidth="true" />
        <Typography style={{ padding: 0 }}>
        <p style={{ fontWeight: 'bold' }}>
        What's this?
        </p>
        <p>
        
        A collection of AI-generated, human-edited Harmontown transcripts, featuring a search engine and media playback solution written in FastAPI framework and ReactJS.
        </p>
        <p style={{ fontWeight: 'bold' }}>
        How does it work?
        </p>
        <p>
        Captioning was performed by OpenAI's Whisper small model. To retrieve audio, the server converts a timestamp pair to milliseconds, then adds 300ms on both ends to reduce truncation in cases where the timestamps are imprecise. Those values are passed as parameters to ffmpeg which instantly streams the targeted content. There's some caching and other stuff involved but that's basically it.
        </p>
        <p style={{ fontWeight: 'bold' }}>
        Why did you do this?
        </p><p>
        <img
             src={`${process.env.PUBLIC_URL}/assets/images/bingle.gif`} 
             alt="bingle"/>
        </p>
        </Typography>
      </div>
        </TableCell>

        
    </ThemeProvider>

    
</Fade>

      </div>
  )}








  {displayPage === "/dan" && (
<div 
  className={classes.danContainer}
     style={{
     paddingLeft: matches ? 0 : 0,
     }}
>
      <Fade in={true}>

    <ThemeProvider theme={theme}>
        <Typography style={{ textAlign: 'center', paddingTop: matches ? 20 : 0, fontWeight: 'bold', fontSize: matches ? '70px' : '30px' }}>Are You Dan Harmon?</Typography>
<Table>
<TableBody>

      <TableCell style={{ zIndex: '0', paddingBottom: '70px', borderBottom: 'none', height: '100%', paddingLeft: 20, paddingRight: 20, alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
       <div>
       <div className={classes.pageContainer} style={{ height: '100%', paddingBottom: '0', borderBottom: 'none', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', alignText: 'left' }}>
        <Divider style={{ padding: 0 }} orientation="horizontal" fullWidth="true" /> 
        {matches === true && (
        <p></p>
        )}
        <Typography style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 10 }}>
        Another fan project, harmon.city, has a page that's just "Are You Dan Harmon?" in huge letters and a contact form. It's like a jump scare. I thought it'd be funny if I did the same thing as a bit. The form doesn't work.
        
        </Typography>
        
        <br />
        <p><TextField size="small" variant="outlined" label="Name" defaultValue="Dan Harmon"/></p>
        <p><TextField size="small" variant="outlined" label="Email"/></p>
        <p><TextField multiline rows={5} color="#2fbebc" style={{ color: '#2fbebc', width: '100%' }} variant="outlined" label="Comment or Message"/></p>
        <Button style={{ backgroundColor: '#2fbebc' }} variant="contained">Submit</Button>

       </div>
       </div>
        </TableCell>
 </TableBody>
 </Table>



        
    </ThemeProvider>
</Fade>
    
      </div>
  )}






<div 
  className={classes.transcriptsContainer}
     style={{ 

     paddingLeft: matches ? 0 : 0,
     }}
>

{displayPage === "/transcripts" && (
  <div style={{ justifyContent: 'center', alignItems: 'center' }}>
{loading === false && selectedFile !== null && ( 
  <div style={{ justifyContent: 'center', alignItems: 'center' }}>
    <Fade in={true}>
      <div style={{ position: 'fixed', bottom: 0, right: 0, zIndex: 9999 }}>
        <Fade in={true}>
          <div style={{ padding: 20 }}>
            <Fab id="download-one-file-button" href={`/api/vtt/download/${selectedFile}`} style={{ backgroundColor: '#2fbebc' }} aria-label="download-file">
              <DownloadIcon style={{ color: '#FFFFFF' }} />
            </Fab>
          </div>
        </Fade>
      </div>
    </Fade>

    <Fade in={true}>
      <ThemeProvider theme={theme}>
        <Table>
          <TableBody style={{ zIndex: '0', height: '100%', paddingLeft: 20, paddingRight: 20, alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <TableCell style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
              <div className={classes.pageContainer} id="page-container-id" style={{ margin: '0 auto', height: '100%', paddingBottom: '0', borderBottom: 'none', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', textAlign: 'left' }}>
                <Typography style={{ paddingBottom: 10, fontWeight: 'bold', fontSize: '20px' }}>{selectedFile}</Typography>
                <Divider style={{ padding: 0 }} orientation="horizontal" fullWidth="true" />
                <p>
                  <Typography variant="body1">
{transcript.split("\n").map((text, index) => (
  <React.Fragment key={index}>
<span
  ref={lineNumber === (index + 1) ? (el) => {
    if (el) {
      lineRef.current = el;

      // Calculate the position to scroll to center the element
      const elementRect = el.getBoundingClientRect();
      const absoluteElementTop = elementRect.top + window.pageYOffset;
      const middleOfScreen = window.innerHeight / 2;
      const scrollPosition = absoluteElementTop - middleOfScreen;

      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });

      // Highlight the line associated with the element
      el.style.backgroundColor = '#FFA756';
    }
  } : undefined}
>
  {text ?? ''}
</span>



    
    <br />
  </React.Fragment>
))}

                  </Typography>
                </p>
              </div>
            </TableCell>
          </TableBody>
        </Table>
      </ThemeProvider>
    </Fade>

    
  </div>
)}

{loading === false && selectedFile !== null && ( 
        <Box style={{ padding: 20, alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', width: 'flex' }}>
  <ThemeProvider theme={theme}>

    
            <Typography variant="caption" style={{ display: 'flex' }}>Time: {DOMPurify.sanitize(cpuTime)}</Typography>
<Typography variant="caption" style={{ display: 'flex' }}>
  Used: {(Number.parseFloat(strSize / (1024 * 1024))).toPrecision(4)} MB
</Typography>

<Typography variant="caption">
  <Link 
    style={{ cursor: 'pointer' }}
    target="_blank"
    rel="noreferrer"
    onClick={() => setDisplayPage('/fairuse')}
  >
    Fair Use Notice
  </Link>
</Typography>


 </ThemeProvider>
            
</Box>
)}




  {loading === false && (
      <div>
        {selectedFile === null && (
         <Fade in={true}>

           <div style={{ position: 'absolute' }}>


           <TableCell style={{ alignItems: 'flex', display: 'flex', justifyContent: 'center', width: 'flex' }}>
            <ThemeProvider theme={headerTheme}>
      
          <Pagination
              siblingCount={siblingCount}
              color="primary"
              count={totalListPages}
              onChange={handleListPageUpdate}
              page={currentListPage}
              style={{ marginTop: 0, marginBottom: 10, display: 'flex', justifyContent: 'center' }}
            />
            </ThemeProvider>
            </TableCell>



       <Box id="enclosing-desktop-and-mobile">

          <div id="desktop-transcript-view" style={{ display: matches ? 'block' : 'none' }}>

               <ThemeProvider theme={theme}>

            <div style={{ position: 'fixed', bottom: 0, right: 0, zIndex: 9999 }}>
              <Fade in={true}>
        <div style={{ padding: 20 }}>

                  <Fab id="download-all-files-button" href={`/api/vtt/download/all`} style={{ backgroundColor: '#2fbebc' }} aria-label="download-all">
            <FolderZipIcon style={{ color: '#FFFFFF' }} />
           </Fab>

              </div>
            </Fade>
          </div>
        </ThemeProvider>


         <Fade in={true}>

           <Box style={{ padding: 0, alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', width: 'flex' }}>
            <Table style={{ width: '100%' }}>



        <TableHead>
          <TableRow>
            <TableCell style={{ width: '50%', textAlign: 'left' }}>
              {sortListKey === 'episode' ? (
                <TableSortLabel
                  active={sortListKey === 'episode'}
                  direction={listSortOrder}
                  onClick={() => handleListSort('episode')}
                >
                  Episode
                </TableSortLabel>
              ) : (
                'Episode'
              )}
            </TableCell>
            <TableCell style={{ textAlign: 'left' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ textAlign: 'left' }}>Description</div>
              </div>
            </TableCell>
          </TableRow>
        </TableHead>






           {files
           .map((file, index) => (

           <TableBody>



           <TableRow key={file}>


             <TableCell style={{ verticalAlign: 'top', padding: 32 }}>
            {filteredNames[index]}
           </TableCell>
           
       <TableCell>
          <Box style={{ padding: 10 }}>
             <Box className={classes.nestedTableRow } style={{ display: "flex", flexDirection: "column" }}>
              <Box style={{ flexDirection: 'column', display: 'flex' }}>
      
                  <TableCell style={{ borderBottom: 'none', padding: 32 }}>
               {descriptions[index]}
                  </TableCell>

             <Divider orientation="horizontal" variant="middle" />
      
             <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <TableCell style={{ borderBottom: 'none' }}>
                 <Box display="flex" justifyContent="center" alignItems="center">



           <TableRow key={file} style={{ justifyContent: 'center', textAlign: 'center', alignItems: 'center', display: 'flex', flex: 1 }}>


        <TableCell style={{ borderBottom: 'none', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>

        <IconButton href={`/api/vtt/download/${file}`} target="_blank" rel="noreferrer">
         <DownloadForOfflineIcon />
          </IconButton>
          </TableCell>


            <TableCell style={{ borderBottom: 'none', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
           <IconButton onClick={() => {
         handlePullTranscript(file);
          }}>
          <ArticleIcon />
        </IconButton>
        </TableCell>




           <TableCell style={{ borderBottom: 'none', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>

       <IconButton href={spotifyLinks[index]}>
          <Icon>
             <img style={{ height: '100%' }} src={spotifyButtonDark ? `${process.env.PUBLIC_URL}/assets/images/spotifydark.svg` : `${process.env.PUBLIC_URL}/assets/images/spotifylight.svg`} alt="spotify" />
            </Icon>
          </IconButton>
             </TableCell>


         <TableCell style={{ borderBottom: 'none', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>

            <IconButton
            href={officialLinks[index]}
               >
            <ExitToAppIcon />
            </IconButton>
         </TableCell>






          </TableRow>

         </Box>
          </TableCell>
            </Box>
            </Box>
              </Box>
           </Box>
           </TableCell>
          </TableRow>

         </TableBody>

          ))}





        </Table>
        </Box>
      </Fade>

    </div>

   <div id="mobile-transcript-view" style={{ display: matches ? 'none' : 'block' }}>


        <ThemeProvider theme={theme}>

        <div style={{ position: 'fixed', bottom: 0, right: 0, zIndex: 9999 }}>
         <Fade in={true}>
        <div style={{ padding: 20 }}>

        <Fab id="download-all-files-button" href={`/api/vtt/download/all`} style={{ backgroundColor: '#2fbebc' }} aria-label="download-all">
        <FolderZipIcon style={{ color: '#FFFFFF' }} />
         </Fab>

        </div>
        </Fade>
        </div>
        </ThemeProvider>


    <Fade in={true}>

      <Box style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', width: 'flex' }}>






          {files
        .map((file, index) => (
        <TableCell style={{ padding: 15 }}>
        
        <div>

            <Table>

        <TableBody>
           <Card style={{ width: '100%' }}>

          <TableRow style={{ display: 'flex', flex: 1 }}>
            <TableCell style={{ padding: '20px', width: '20%', textAlign: 'left', flex: 1 }}>Episode</TableCell>

            <TableCell style={{ padding: '20px', width: '80%', textAlign: 'left', flex: 3 }}>{filteredNames[index]}</TableCell>
          </TableRow>

            <Collapse in={listCollapsed[index]}>


          <TableRow style={{ display: 'flex', flex: 1 }}>
            <TableCell style={{ padding: '20px', width: '20%', textAlign: 'left', flex: 1 }}>Description</TableCell>

            <TableCell style={{ padding: '20px', width: '80%', textAlign: 'left', flex: 3 }}>{descriptions[index]}</TableCell>
          </TableRow>

         </Collapse>

          <TableRow key={file} style={{ justifyContent: 'center', textAlign: 'center', alignItems: 'center', display: 'flex', flex: 1 }}>



        <TableCell style={{ borderBottom: 'none', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>

        <IconButton href={`/api/vtt/download/${file}`} target="_blank" rel="noreferrer">
         <DownloadForOfflineIcon />
          </IconButton>
          </TableCell>


            <TableCell style={{ borderBottom: 'none', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
           <IconButton onClick={() => {
         handlePullTranscript(file);
          }}>
          <ArticleIcon />
        </IconButton>
        </TableCell>




           <TableCell style={{ borderBottom: 'none', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>

       <IconButton href={spotifyLinks[index]}>
          <Icon>
             <img style={{ height: '100%' }} src={spotifyButtonDark ? `${process.env.PUBLIC_URL}/assets/images/spotifydark.svg` : `${process.env.PUBLIC_URL}/assets/images/spotifylight.svg`} alt="spotify" />
            </Icon>
          </IconButton>
             </TableCell>


         <TableCell style={{ borderBottom: 'none', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>

            <IconButton
            href={officialLinks[index]}
               >
            <ExitToAppIcon />
            </IconButton>
         </TableCell>




          </TableRow>


         </Card>

           </TableBody>
         </Table>
      
 
          <Table>
           <TableBody>
           <Box style={{ display: 'flex', flex: 1, textAlign: 'center', width: '100%', justifyContent: 'center' }}>
          <TableCell style={{ borderBottom: 'none', width: '100%', textAlign: 'center' }}>
            <Box style={{ width: '100%' }} display="flex" justifyContent="center" alignItems="center" alignText="center">

              <Collapse in={listCollapsed[index]}>
                <div style={{ flexDirection: 'column', width: '100%', textAlign: 'center' }}>


                </div>
              </Collapse>
              <Box style={{ textAlign: 'center', justifyContent: 'center', width: '100%' }}>
              <IconButton onClick={() => handleListCollapse(index)}>
                {listCollapsed[index] ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
              </Box>
            </Box>
          </TableCell>
          </Box>
            </TableBody>
           </Table>


          </div>
          </TableCell>
          ))}



        </Box>



        
      </Fade>

       </div>

   </Box>

           <TableCell style={{ alignItems: 'flex', display: 'flex', justifyContent: 'center', width: 'flex' }}>
            <ThemeProvider theme={headerTheme}>
      
          <Pagination
              siblingCount={siblingCount}
              color="primary"
              count={totalListPages}
              onChange={handleListPageUpdate}
              page={currentListPage}
              style={{ marginTop: 0, marginBottom: 10, display: 'flex', justifyContent: 'center' }}
            />
            </ThemeProvider>
            </TableCell>

        <Box style={{ padding: 20, alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', width: 'flex' }}>
  <ThemeProvider theme={theme}>

    
            <Typography variant="caption" style={{ display: 'flex' }}>Time: {DOMPurify.sanitize(cpuTime)}</Typography>
<Typography variant="caption" style={{ display: 'flex' }}>
  Used: {(Number.parseFloat(strSize / (1024 * 1024))).toPrecision(4)} MB
</Typography>

<Typography variant="caption">
  <Link 
    style={{ cursor: 'pointer' }}
    target="_blank"
    rel="noreferrer"
    onClick={() => setDisplayPage('/fairuse')}
  >
    Fair Use Notice
  </Link>
</Typography>


 </ThemeProvider>
            
</Box>

</div>
</Fade>
)}
</div>
)}
</div>
)}
</div>









  <div 
    className={classes.fairuseContainer}
    style={{
      paddingLeft: matches ? 0 : 0,
      
    }}
  >

{displayPage === "/fairuse" && (





      <Fade in={true}>


    <ThemeProvider theme={theme}>
           <TableCell style={{ zIndex: '0', paddingBottom: '50px', borderBottom: 'none', height: '100%', paddingLeft: 20, paddingRight: 20, alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
       <div className={classes.pageContainer} style={{ height: '100%', paddingBottom: '0', borderBottom: 'none', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', alignText: 'left' }}>
            



        <Typography style={{ paddingBottom: 10, fontWeight: 'bold', fontSize: '20px' }}>Fair Use Notice</Typography>
        <Divider style={{ padding: 0 }} orientation="horizontal" fullWidth="true" /> 
   <Typography style={{ paddingBottom: 0 }}>
        <p>
        The "Harmontown" copyright is managed by its publisher, Harmontown Productions, LLC. This website is not endorsed by, affiliated with, maintained, authorized, or sponsored by Harmontown Productions, LLC. All product and company names are the registered trademarks of their original owners.
        </p>
        <p>
        Any transcripts, recordings and other materials made available on this site are provided without profit for educational purposes only and are protected by the "Fair Use Doctrine" of United States copyright law which allows for the reproduction of copyrighted works for the purpose of criticism, comment, news reporting, teaching, scholarship, or research. These materials may contain errors or omissions.
        </p>
        <p>
        By using this site, you agree that you will not hold Harmontown Productions, LLC accountable for any errors or omissions in the information provided, or for any claims or damages arising from your use of the site. If you wish to use copyrighted material from this site for purposes of your own that go beyond fair use, you must obtain permission from the copyright owner.
        </p>
     </Typography>
  </div>
  </TableCell>








</ThemeProvider>
</Fade>


 
)}

</div>







      </Box>

         
    </Box>
   </>

    )
};

const SearchResults = ({ spotifyButtonDark, setSpotifyButtonDark, handlePullAndGo, setListCollapsed, siblingCount, setDisplayPage, handlePageUpdate, totalPages, matches, isOpen, setIsOpen, classes, useEffect, results, query, perPage, currentPage, sortKey, sortOrder, setSortKey, setSortOrder, setCurrentPage, setLoading, loading }) => {


	const [listen, setListen] = useState({});
	const [openPlayers, setOpenPlayers] = useState([]);
	const [currentOpenEpisode, setCurrentOpenEpisode] = useState(null);
	const handleListen = (episode, index) => {
		// Close the current open audio player if there is one
		if (currentOpenEpisode !== null && currentOpenEpisode !== episode) {
			setListen((prevListen) => {
				const newListen = { ...prevListen };
				delete newListen[currentOpenEpisode];
				return newListen;
			});
		}
		// Update the state with the new open audio player
		setListen((prevListen) => {
			const newListen = { ...prevListen };
			newListen[episode] = index;
			return newListen;
		});
		setCurrentOpenEpisode(episode);
	};
	const audioPlayer = useState(null);

        useEffect(() => {
                setCollapsed({});
                setListen({});
        }, [currentPage]);



const [collapsed, setCollapsed] = useState({});
const handleCollapse = (episode) => {
  setCollapsed({ ...collapsed, [episode]: !collapsed[episode] });
};





	const handleSort = (key) => {
		setSortKey(key);
		setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
	};


	
	const HighlightedText = ({ text, queriedWord }) => {
		const regex = new RegExp(queriedWord, 'gi');
		const parts = text.split(regex);
		const wordMatches = text.match(regex) || [];
		return (<>
      {parts.map((part, index) => (
        <React.Fragment key={index}>
          {part}
          {wordMatches[index] && <span style={{ backgroundColor: '#FFA756' }}>{wordMatches[index]}</span>}
        </React.Fragment>
      ))}
    </>);
	};
	const highlightText = (text, queriedWord) => {
		return <HighlightedText text={text} queriedWord={queriedWord} />;
	};
	const currentResults = results.sort((a, b) => {
		if (sortKey === 'episode') {
			if (sortOrder === 'asc') {
				return a.episode.localeCompare(b.episode);
			}
			return b.episode.localeCompare(a.episode);
		}
		return 0;
	}).slice((currentPage - currentPage) * perPage, currentPage * perPage);

	
	return (
	<>


            <CssBaseline />

            
      {loading === false && currentResults.length > 0 ? (
<Fade in={true}>

<div style={{ width: '100%' }}>



  <TableCell style={{ alignItems: 'flex', display: 'flex', justifyContent: 'center', width: 'flex' }}>
<ThemeProvider theme={headerTheme}>
      <Pagination
              siblingCount={siblingCount}
              color="primary"
              count={totalPages}
              page={currentPage}
              onChange={handlePageUpdate}
              style={{ marginTop: -5, marginBottom: 10, display: 'flex', justifyContent: 'center' }}
            />
            </ThemeProvider>

  </TableCell>



<Box>



          <div id="desktop-results-view" style={{ display: matches ? 'block' : 'none' }}>

<TableContainer component={Box}>
      <Table aria-label="search results table">
        <TableHead>
          <TableRow>
            <TableCell style={{ width: '25%', textAlign: 'left' }}>
              {sortKey === 'episode' ? (
                <TableSortLabel
                  active={sortKey === 'episode'}
                  direction={sortOrder}
                  onClick={() => handleSort('episode')}
                >
                  Episode
                </TableSortLabel>
              ) : (
                'Episode'
              )}
            </TableCell>
            <TableCell style={{ width: '100%', textAlign: 'left' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '100%', textAlign: 'left' }}>Time</div>
                <div style={{ width: '100%', textAlign: 'left' }}>Line</div>
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
          <TableBody>
          
            {currentResults.map(result => (
              <React.Fragment key={result.file}>


      <TableRow className={classes.tableRow}>
        <TableCell style={{ verticalAlign: 'top', padding: 32 }}>
        {DOMPurify.sanitize(result.episode.replace(/(^|\D)0+(\d)/g, '$1$2'))}
        </TableCell>
        <TableCell style={{ width: '100%' }}>
          <Box id="top-box" className={classes.nestedBox} style={{ paddingBottom: '10px' }}>
            <Box style={{ display: 'flex', flex: 1 }}>
              <TableCell className={classes.nestedTimingCell}>
                {DOMPurify.sanitize(result.start_timing[0])}
              </TableCell>
              <TableCell className={classes.nestedLineCell}>
                {highlightText(
                  DOMPurify.sanitize(result.line[0]),
                  result.queried_word
                )}
              </TableCell>
            </Box>
            <Divider orientation="horizontal" variant="middle" />
            <Box style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
              <TableCell style={{ borderBottom: 'none' }}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  {listen[result.episode] === 0 ? null : (

                    <Box
                      style={{
                        height: 81,
                        display: 'flex',
                        textAlign: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      p={1}
                    >
                    
           <TableRow style={{ justifyContent: 'center', textAlign: 'center', alignItems: 'center', display: 'flex', flex: 1 }}>


        <TableCell style={{ borderBottom: 'none', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>

                      <IconButton
                        className={classes.button}
                        onClick={() => {
                          handleListen(result.episode, 0);
                        }}
                      >
                        <HeadphonesIcon />
                      </IconButton>

</TableCell>

            <TableCell style={{ borderBottom: 'none', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>

           <IconButton onClick={() => {
         handlePullAndGo(result.filename, result.line_number[0]);
          }}>
          <ArticleIcon />
        </IconButton>
        </TableCell>





           <TableCell style={{ borderBottom: 'none', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>

       <IconButton href={result.spotify_links}>
          <Icon>
             <img style={{ height: '100%' }} src={spotifyButtonDark ? `${process.env.PUBLIC_URL}/assets/images/spotifydark.svg` : `${process.env.PUBLIC_URL}/assets/images/spotifylight.svg`} alt="spotify" />
            </Icon>
          </IconButton>
             </TableCell>


         <TableCell style={{ borderBottom: 'none', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>

            <IconButton
            href={result.official_links}
               >
            <ExitToAppIcon />
            </IconButton>
         </TableCell>

</TableRow>
</Box>
                    
                  )}
                  {listen[result.episode] === 0 && (
                    <Box
                      style={{
                        height: 81,
                        display: 'flex',
                        textAlign: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      p={1}
                    >
                      <ReactAudioPlayer
                        style={{ justifyContent: 'center' }}
                        className={classes.audio}
                        src={
                          process.env.PUBLIC_URL +
                          '/api/mp3/' +
                          DOMPurify.sanitize(result.mp3) +
                          '?start_time=' +
                          DOMPurify.sanitize(result.start_milliseconds[0]) +
                          '&end_time=' +
                          DOMPurify.sanitize(result.end_milliseconds[0]) +
                          '&line_number=' +
                          DOMPurify.sanitize(result.line_number[0])
                        }
                        controls
                        preload="auto"
                        ref={audioPlayer}
                        onEnded={() => {
                          setListen({
                            ...listen,
                            [result.episode]: null,
                          });
                          setOpenPlayers(
                            openPlayers.filter(
                              (player) =>
                                player.episode !== result.episode ||
                                player.index !== 0
                            )
                          );
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </TableCell>
            </Box>
          </Box>

          {result.start_timing.slice(1).map((start_timing, i) => (
            <Collapse in={collapsed[result.episode]} key={i}>
              <Box id="collapsed-boxes" className={classes.nestedBox} style={{ paddingBottom: '10px' }}>
                <Box style={{ display: 'flex', flex: 1 }}>
                  <TableCell className={classes.nestedTimingCell}>
                    {DOMPurify.sanitize(result.start_timing[i + 1])}
                  </TableCell>
                  <TableCell className={classes.nestedLineCell}>
                                  {highlightText(
                                    DOMPurify.sanitize(result.line[i + 1]),
                                    result.queried_word
                                  )}
                  </TableCell>
                </Box>
                <Divider orientation="horizontal" variant="middle" />
                <Box style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                  <TableCell style={{ borderBottom: 'none' }}>
                    <Box display="flex" justifyContent="center" alignItems="center">
                      {listen[result.episode] === i + 1 ? null : (

                                          <Box
                      style={{
                        height: 81,
                        display: 'flex',
                        textAlign: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      p={1}
                    >
           <TableRow style={{ justifyContent: 'center', textAlign: 'center', alignItems: 'center', display: 'flex', flex: 1 }}>


        <TableCell style={{ borderBottom: 'none', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>

                  <IconButton className={classes.button} onClick={() => {
                    handleListen(result.episode, i + 1);
                  }}>
                    <HeadphonesIcon />
                  </IconButton>


         </TableCell>

            <TableCell style={{ borderBottom: 'none', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>

           <IconButton onClick={() => {
                    handlePullAndGo(result.filename, result.line_number[i + 1]);
          }}>
          <ArticleIcon />
        </IconButton>
        </TableCell>


	


           <TableCell style={{ borderBottom: 'none', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>

       <IconButton href={result.spotify_links}>
          <Icon>
             <img style={{ height: '100%' }} src={spotifyButtonDark ? `${process.env.PUBLIC_URL}/assets/images/spotifydark.svg` : `${process.env.PUBLIC_URL}/assets/images/spotifylight.svg`} alt="spotify" />
            </Icon>
          </IconButton>
             </TableCell>


         <TableCell style={{ borderBottom: 'none', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>

            <IconButton
            href={result.official_links}
               >
            <ExitToAppIcon />
            </IconButton>
         </TableCell>

          </TableRow>
          </Box>
                      )}
                      {listen[result.episode] === i + 1 && (
                        <Box
                      style={{
                        height: 81,
                        display: 'flex',
                        textAlign: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                          p={1}
                        >
                          <ReactAudioPlayer
                            style={{ justifyContent: 'center' }}
                            className={classes.audio}
                        src={
                          process.env.PUBLIC_URL +
                          '/api/mp3/' +
                          DOMPurify.sanitize(result.mp3) +
                          '?start_time=' +
                          DOMPurify.sanitize(result.start_milliseconds[i + 1]) +
                          '&end_time=' +
                          DOMPurify.sanitize(result.end_milliseconds[i + 1]) +
                          '&line_number=' +
                          DOMPurify.sanitize(result.line_number[i + 1])
                        }
                            controls
                            preload="auto"
                            ref={audioPlayer}
                            onEnded={() => {
                              setListen({
                                ...listen,
                                [result.episode]: null,
                              });
                              setOpenPlayers(
                                openPlayers.filter(
                                  (player) =>
                                    player.episode !== result.episode ||
                                    player.index !== i + 1
                                )
                              );
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                  </TableCell>
                </Box>
              </Box>
            </Collapse>
          ))}

          {result.start_timing.length > 1 && (
            <Box className={classes.buttonContainer}>
              <IconButton
                className={classes.button}
                onClick={() => handleCollapse(result.episode)}
              >
                {!collapsed[result.episode] ? <ExpandMore /> : <ExpandLess />}
              </IconButton>
            </Box>
          )}

          
        </TableCell>
      </TableRow>


              </React.Fragment>

            ))}

          </TableBody> 
        </Table>
      </TableContainer>
</div>


<div id="mobile-results-view" style={{ display: matches ? 'none' : 'block' }}>


<TableContainer component={Box}>


  {currentResults.map(result => (
  <React.Fragment key={result.file}>

  
    <TableRow style={{ height: '100%' }} className={classes.tableRow}>
      <TableCell style={{ display: 'flex', width: '100%', height: '100%' }}>
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>



          
              <Box style={{ paddingBottom: '10px', width: '100%' }}>
                <Card style={{ width: '100%' }}>
          <Table>
          <TableRow style={{ display: 'flex', flex: 1 }}>
            <TableCell style={{ padding: '20px', width: '20%', textAlign: 'left', flex: 1 }}>Episode</TableCell>

            <TableCell style={{ padding: '20px', width: '80%', textAlign: 'left', flex: 3 }}>{DOMPurify.sanitize(result.episode.replace(/(^|\D)0+(\d)/g, '$1$2'))}</TableCell>
          </TableRow>
          <TableRow style={{ display: 'flex', flex: 1 }}>
            <TableCell style={{ padding: '20px', width: '20%', textAlign: 'left', flex: 1 }}>Time</TableCell>
           
            <TableCell style={{ padding: '20px', width: '80%', textAlign: 'left', flex: 3 }}>{DOMPurify.sanitize(result.start_timing[0])}</TableCell>
          </TableRow>
          <TableRow style={{ display: 'flex', flex: 1 }}>
            <TableCell style={{ padding: '20px', width: '20%', textAlign: 'left', flex: 1 }}>Line</TableCell>
            
            <TableCell style={{ padding: '20px', width: '80%', textAlign: 'left', flex: 3 }}>{highlightText(DOMPurify.sanitize(result.line[0]), result.queried_word)}</TableCell>
          </TableRow>
  {listen[result.episode] === 0 ? null : (


                    <Box
                      style={{
                        height: 81,
                        display: 'flex',
                        textAlign: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      p={1}
                    >

           <TableRow style={{ justifyContent: 'center', textAlign: 'center', alignItems: 'center', display: 'flex', flex: 1 }}>


           <TableCell style={{ borderBottom: 'none', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>


                  <IconButton className={classes.button} onClick={() => {
                    handleListen(result.episode, 0);
                  }}>
                    <HeadphonesIcon />
                  </IconButton>
           </TableCell>

            <TableCell style={{ borderBottom: 'none', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>

           <IconButton onClick={() => {
                  handlePullAndGo(result.filename, result.line_number[0]);
          }}>
          <ArticleIcon />
        </IconButton>
        </TableCell>



           <TableCell style={{ borderBottom: 'none', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>

       <IconButton href={result.spotify_links}>
          <Icon>
             <img style={{ height: '100%' }} src={spotifyButtonDark ? `${process.env.PUBLIC_URL}/assets/images/spotifydark.svg` : `${process.env.PUBLIC_URL}/assets/images/spotifylight.svg`} alt="spotify" />
            </Icon>
          </IconButton>
             </TableCell>


         <TableCell style={{ borderBottom: 'none', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>

            <IconButton
            href={result.official_links}
               >
            <ExitToAppIcon />
            </IconButton>
         </TableCell>

          </TableRow>

                </Box>
              )}

              
                  {listen[result.episode] === 0 && (
                    <Box
                      style={{
                        height: 81,
                        display: 'flex',
                        textAlign: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      p={1}
                    >
                      <ReactAudioPlayer
                        style={{ justifyContent: 'center' }}
                        className={classes.audio}
                        src={
                          process.env.PUBLIC_URL +
                          '/api/mp3/' +
                          DOMPurify.sanitize(result.mp3) +
                          '?start_time=' +
                          DOMPurify.sanitize(result.start_milliseconds[0]) +
                          '&end_time=' +
                          DOMPurify.sanitize(result.end_milliseconds[0]) +
                          '&line_number=' +
                          DOMPurify.sanitize(result.line_number[0])
                        }
                        controls
                        preload="auto"
                        ref={audioPlayer}
                        onEnded={() => {
                          setListen({
                            ...listen,
                            [result.episode]: null,
                          });
                          setOpenPlayers(
                            openPlayers.filter(
                              (player) =>
                                player.episode !== result.episode ||
                                player.index !== 0
                            )
                          );
                        }}
                      />
                    </Box>
                  )}
                  
          </Table>
         </Card>
      </Box>


{result.start_timing.slice(1).map((start_timing, i) => (
<Collapse in={collapsed[result.episode]} key={i}>

              <Box style={{ paddingBottom: '10px', width: '100%' }}>
                <Card style={{ width: '100%' }}>
          <Table>
          <TableRow style={{ display: 'flex', flex: 1 }}>
            <TableCell style={{ padding: '20px', width: '20%', textAlign: 'left', flex: 1 }}>Episode</TableCell>

            <TableCell style={{ padding: '20px', width: '80%', textAlign: 'left', flex: 3 }}>{DOMPurify.sanitize(result.episode.replace(/(^|\D)0+(\d)/g, '$1$2'))}</TableCell>
          </TableRow>
          <TableRow style={{ display: 'flex', flex: 1 }}>
            <TableCell style={{ padding: '20px', width: '20%', textAlign: 'left', flex: 1 }}>Time</TableCell>
           
            <TableCell style={{ padding: '20px', width: '80%', textAlign: 'left', flex: 3 }}>{DOMPurify.sanitize(result.start_timing[i + 1])}</TableCell>
          </TableRow>
          <TableRow style={{ display: 'flex', flex: 1 }}>
            <TableCell style={{ padding: '20px', width: '20%', textAlign: 'left', flex: 1 }}>Line</TableCell>
            
            <TableCell style={{ padding: '20px', width: '80%', textAlign: 'left', flex: 3 }}>{highlightText(DOMPurify.sanitize(result.line[i + 1]), result.queried_word)}</TableCell>
          </TableRow>

 
  {listen[result.episode] === i + 1 ? null : (


                    <Box
                      style={{
                        height: 81,
                        display: 'flex',
                        textAlign: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      p={1}
                    >

           <TableRow style={{ justifyContent: 'center', textAlign: 'center', alignItems: 'center', display: 'flex', flex: 1 }}>


           <TableCell style={{ borderBottom: 'none', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>


                  <IconButton className={classes.button} onClick={() => {
                    handleListen(result.episode, i + 1);
                  }}>
                    <HeadphonesIcon />
                  </IconButton>

           </TableCell>

            <TableCell style={{ borderBottom: 'none', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>

           <IconButton onClick={() => {
         handlePullAndGo(result.filename, result.line_number[i + 1]);
          }}>
          <ArticleIcon />
        </IconButton>
        </TableCell>



           <TableCell style={{ borderBottom: 'none', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>

       <IconButton href={result.spotify_links}>
          <Icon>
             <img style={{ height: '100%' }} src={spotifyButtonDark ? `${process.env.PUBLIC_URL}/assets/images/spotifydark.svg` : `${process.env.PUBLIC_URL}/assets/images/spotifylight.svg`} alt="spotify" />
            </Icon>
          </IconButton>
             </TableCell>


         <TableCell style={{ borderBottom: 'none', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>

            <IconButton
            href={result.official_links}
               >
            <ExitToAppIcon />
            </IconButton>
         </TableCell>

          </TableRow>
</Box>
                
              )}
            {listen[result.episode] === i + 1 && (
              <Box     
                      style={{
                        height: 81,
                        display: 'flex',
                        textAlign: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                       p={1}>
                <ReactAudioPlayer
                  style={{ justifyContent: 'center' }}
                  className={classes.audio}
                        src={
                          process.env.PUBLIC_URL +
                          '/api/mp3/' +
                          DOMPurify.sanitize(result.mp3) +
                          '?start_time=' +
                          DOMPurify.sanitize(result.start_milliseconds[i + 1]) +
                          '&end_time=' +
                          DOMPurify.sanitize(result.end_milliseconds[i + 1]) +
                          '&line_number=' +
                          DOMPurify.sanitize(result.line_number[i + 1])
                        }
                  controls
                  preload="auto"
                  ref={audioPlayer}
                  onEnded={() => {
                    setListen({
                      ...listen,
                      [result.episode]: null
                    });
                    setOpenPlayers(openPlayers.filter((player) => player.episode !== result.episode || player.index !== i + 1));
                  }}
                />
              </Box>
            )}
       

            
          </Table>
         </Card>
      </Box>


</Collapse>
))}


            
          {result.start_timing.length > 1 && (
            <Box className={classes.buttonContainer}>
              <IconButton
                className={classes.button}
                onClick={() => handleCollapse(result.episode)}
              >
                {!collapsed[result.episode] ? <ExpandMore /> : <ExpandLess />}
              </IconButton>
            </Box>
          )}


          </div>
        </TableCell>
      </TableRow>
    </React.Fragment>
  ))}
</TableContainer>
                    </div>
</Box>

  <TableCell style={{ alignItems: 'flex', display: 'flex', justifyContent: 'center', width: 'flex' }}>
<ThemeProvider theme={headerTheme}>
  
      <Pagination
              siblingCount={siblingCount}
              color="primary"
              count={totalPages}
              page={currentPage}
              onChange={handlePageUpdate}
              style={{ marginTop: 10, marginBottom: 10, display: 'flex', justifyContent: 'center' }}
            />
            </ThemeProvider>
  
  </TableCell>

        <Box style={{ padding: 20, alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', width: 'flex' }}>
  <ThemeProvider theme={theme}>

    
            <Typography variant="caption" style={{ display: 'flex' }}>Time: {DOMPurify.sanitize(results[0].cpu_time)}</Typography>
<Typography variant="caption" style={{ display: 'flex' }}>
  Used: {(Number.parseFloat(results[0].str_size / (1024 * 1024))).toPrecision(4)} MB
</Typography>

<Typography variant="caption">
  <Link 
    style={{ cursor: 'pointer' }}
    target="_blank"
    rel="noreferrer"
    onClick={() => setDisplayPage('/fairuse')}
  >
    Fair Use Notice
  </Link>
</Typography>


 </ThemeProvider>
            
</Box>
      
      </div>

</Fade>

) : 
null
}

</>
);
};

const SearchBar = ({ setListCollapsed, setFiles, setResults, setDisplayPage, setQuery, setHistoryDrawerOpen, historyDrawerOpen, setIsOpen, isOpen, results, handleDrawerToggle, matches, query, onSubmit }) => {

const ref = useRef('');

  const handleMenuButton = (event) => {
    setIsOpen(!isOpen);
    if (historyDrawerOpen === true) {
      setHistoryDrawerOpen(false);
    }
  };

  const handleChange = (event) => {
    setQuery(event.target.value);
  };

  const handleSearchButton = (event) => {
    setDisplayPage('/results');
    setListCollapsed({});
    setFiles([]);
    setResults([]);
    onSubmit(event);
  };

  const handleEnterKey = (event) => {
    if (event.key === 'Enter') {
      setListCollapsed({});
      setFiles([]);
      setResults([]);
      setDisplayPage('/results');
      onSubmit(event);
    }
  };



  return (
    <>
        <ThemeProvider theme={theme}>
          <div style={{ height: 50 }}>
            <Paper component="form" sx={{ display: 'inline-block', alignItems: 'inline-block' }}>

              <IconButton style={{ display: matches ? 'none' : 'inline-block' }} aria-label="menu" onClick={handleMenuButton}>
                <MenuIcon />
              </IconButton>
              <InputBase
                onClick={e => {
                   
                   setHistoryDrawerOpen(false);
                   setIsOpen(false);
                   setTimeout(() => ref.current.focus());
                }}
                sx={{ zIndex: 9999, pl: matches ? 2 : 1 }}
                color="primary"
                value={query}
                inputRef={ref}
                onChange={handleChange}
                onKeyPress={handleEnterKey}
                
                placeholder="Search"
                inputProps={{ 'aria-label': 'search' }}
              />

              <IconButton type="submit" style={{ display: 'inline-block' }} aria-label="search" onClick={handleSearchButton}>
                <SearchIcon />
              </IconButton>

            </Paper>
          </div>
        </ThemeProvider>
    </>
  );
};

export default Search;

